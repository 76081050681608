<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2022-07-12 16:28:37
 * @LastEditTime : 2022-07-20 16:57:36
 * @LastEditors  : BigBigger
-->
<template>
  <div class="through-pay" v-if="detail.orderThroughPay === null">
    <div class="through-pay-title">请选择付费模式（2选1）：</div>
    <div class="through-pay-select" :class="{'active': value === 1}" @click="change(1)">{{THROUGH_PAY[1]}}</div>
    <div class="through-pay-select" :class="{'active': value === 0}" @click="change(0)">
      {{THROUGH_PAY[0]}}
      <img style="position: absolute;right: -0.15rem;top:-0.15rem;" src="@/assets/images/icon/youhui.png" alt="" />
    </div>
    <div class="through-pay-tip">说明：{{value === 1?detail.msg1: detail.msg2}}</div>
    <div class="price">￥{{value === 1?detail.showPrice: detail.currentPrice}}</div>
  </div>
  <div class="through-pay" v-else>
    <div class="through-pay-title">您选择的付费模式：</div>
    <div v-if="detail.orderThroughPay === 0" class="through-pay-select" :class="{'active': detail.orderThroughPay === 0}">
      {{THROUGH_PAY[0]}}
      <img style="position: absolute;right: -0.15rem;top:-0.15rem;" src="@/assets/images/icon/youhui.png" alt="" />
    </div>
    <div v-if="detail.orderThroughPay === 1" class="through-pay-select" :class="{'active': detail.orderThroughPay === 1}">{{THROUGH_PAY[1]}}</div>
    <div class="through-pay-tip">说明：{{detail.orderThroughPay === 1?detail.msg1: detail.msg2}}</div>
    <div class="price">￥{{detail.orderThroughPay === 1?detail.showPrice: detail.currentPrice}}</div>
  </div>
</template>

<script>
import { THROUGH_PAY } from '@/config/config';

export default {
  name: "ThroughPay",
  model: {
    props: 'value',
    event: 'change',
  },
  props: {
    value: {type: Number, required: true},
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {THROUGH_PAY}
  },
  methods: {
    change(val) {
      this.$emit('change', val)
    }
  },
}
</script>

<style scoped lang="less">
  .through-pay {
    background: #fff;
    font-size: 14px;
    padding: 0.2rem 0.4rem;
    color: #333;
    margin-top: 0.2rem;
    &-select {
      font-size: 14px;
      height: 35px;
      line-height: 35px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px 0 20px;
      background: #F2F2F2;
      margin: 10px 0;
      border: 1px solid transparent;
      position: relative;
      &.active {
        color: #FF3F47;
        background: #FDEFEF;
        border-color: #FF7773;
      }
    }
    &-tip {
      color: #FF3F47;
    }
    .price {
      color: #FF3F47;
      height: 40px;
      line-height: 40px;
      display: flex;
      padding: 10px 0;
      font-size: 20px;
      color: #FF3F47;
      font-weight: bold;
    }
  }
</style>