<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-03-26 17:55:09
 * @LastEditTime : 2022-07-13 14:49:52
 * @LastEditors  : BigBigger
-->
<template>
  <div class="detail-card" v-if="detail">
    <div class="inc-content" v-if="detail.whetheHaveClassManager === 2 && detail.throughPay !== 1">
      <div class="title">{{detail.courseGroupName}}</div>
      <div class="tip red">{{detail.subtitle}}</div>
      <div class="tip-price1">
        <span class="price-now">￥{{detail.currentPrice}}</span>
        <span class="price-old" v-if="detail.originalPrice">￥{{detail.originalPrice}}</span>
        <span class="preferential">{{detail.costDescription}}</span>
      </div>
      <div class="tip-arrange">
        <p class="tip-time">
          <img src="@/assets/images/h5-icon/time.png">
          <span>时间：{{detail.classTime}} </span>
        </p>
        <p class="tip-type" v-if="teachers">
          <img src="@/assets/images/h5-icon/type.png">
          <span>大课授课老师：{{ teachers }}</span>
          <!-- <span>上课形式：{{detail.categoryName}}{{detail.classWay}} </span> -->
        </p>
        <p class="tip-address" v-if="detail.classLocation">
          <img src="@/assets/images/h5-icon/address.png">
          <span>上课地址：{{detail.classLocation}} </span>
        </p>
      </div>
      <div class="tip-matter" v-if="detail.protocolId" @click="toProtocol">
        <i class="matter-icon"></i>
        <span class="matter-title">课程协议</span>
        <span class="matter-cont">协议模板</span>
        <van-icon name="arrow" />
      </div>
      <!-- <div class="tip-matter">
        <i></i>
        <span class="matter-title">配套网课?</span>
        <span class="matter-cont">配套课程免费观看?</span>
      </div> -->
    </div>
    <div class="inc-content content2" v-else>
      <div class="title">{{detail.courseGroupName}}</div>
      <div class="tip">{{detail.subtitle}}</div>
      <div class="tip tip2">
        <p class="tip-time">
          <span>时间：{{detail.classTime}} </span>
        </p>
        <p class="tip-type" v-if="teachers">
          <span>大课授课老师：{{ teachers }}</span>
          <!-- <span>上课形式：{{detail.categoryName}}{{detail.classWay}} </span> -->
        </p>
        <!-- <p class="tip-address" v-if="detail.classLocation">
          <span>上课地址：{{detail.classLocation}} </span>
        </p> -->
      </div>
    </div>
  </div>
  <!-- <div class="detail-card" v-if="detail">
    <div class="inc-img">
      <img :src="detail.courseCover" alt="">
    </div>
    <div class="inc-content">
      <div class="title">{{detail.courseGroupName}}</div>
      <div class="tip">{{detail.subtitle}}</div>
      <div class="tip-time"><span style="color:#999;">时间：</span>{{detail.classTime}}</div>
      <div class="tip-type">
        上课形式：
        <span>{{detail.categoryName}}</span>
      </div>
      <div class="tip-address">
        <div v-if="detail.classLocation">
          上课地址：
          <span>{{detail.classLocation}}</span>
        </div>
      </div>
      <div class="bottom">
        <template v-if="detail.whetherReation === 2">
          <button class="buy-btn" @click="confirm(btnStateComputed.type)" v-if="btnStateComputed.canClick">{{btnStateComputed.state}}</button>
          <button class="buy-btn buy-btn-gray" v-if="!btnStateComputed.canClick">{{btnStateComputed.state}}</button>
        </template>
        <template v-if="detail.whetherReation !== 2">
          <button class="buy-btn buy-btn-gray" @click="ptclassTip">配套课程</button>
        </template>
        <span class="price-now">￥{{detail.currentPrice}}</span>
        <span class="price-old" v-if="detail.originalPrice">￥{{detail.originalPrice}}</span>
      </div>
    </div>
  </div> -->
</template>

<script>
import { BTNSTATEFILTER } from '@/config/config'
import { getLessonGroupTips } from '@/api/api'
export default {
  name: "CourseGroupDetailCard",
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    async getLessonGroupTips() {
      let params = {
        courseGroupId: this.detail.id
      }
      let response = await getLessonGroupTips(params)
      if(+response.returnCode !== 10001) {
        return false
      }
      this.orderId = response.data.orderId
    },
    ptclassTip() {
      this.$toast('该课程无法购买')
    },
    confirm(type = 0) {
      if(type === 0) {
        if(this.$store.state.userInfo && this.$store.state.userInfo.token) {
          this.$store.commit('order/setOrderInfo',{
            courseGroupId: this.detail.id,
            courseGroupName: this.detail.courseGroupName,
            jobListId: this.detail.jobListId,
            loCourseInfo: this.detail
          })
          this.$nextTick(() => {
            this.$emit('confirm',this.detail.interviewFlag)
          })
        } else {
          this.$store.commit('login/showDialog')
        }
      } else {
        this.$router.push({path: `/course/signup?orderId=${this.orderId}`})
      }
    },
    toProtocol() {
      this.$router.push({
        name: 'protocol',
        params: {
          id: this.detail?.protocolId
        }
      })
    },
  },
  mounted() {
    this.getLessonGroupTips()
  },
  computed: {
    btnStateComputed() {
      return BTNSTATEFILTER(this.detail)
    },
    teachers() {
      return (this.detail.teachers || []).map(i => i.teacherName).join('、')
    }
  }
}
</script>

<style lang="less" scoped>
.detail-card{
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding:0.2rem 0.4rem;
}
.inc-content{
  color:#333333;
  text-align: left;
  width:100%;
}
.inc-content .title{
  font-size: 22px;
  font-weight: 700;
  line-height: .65rem;
  padding: .15rem 0;
  
}
.inc-content .tip{
  color:#999999;
  font-size: 14px;
  line-height: 0.4rem;
}
.inc-content .tip-price1{
  // height:0.5rem;
  // line-height: 0.5rem;
  display: flex;
  padding:0.18rem 0;
  align-items: center;
}
.inc-content .tip-price1 .price-now{
  font-size: 20px;
  color:#FF3F47;
  font-weight: bold;
  margin-right: 0.15rem;
}
.inc-content .tip-price1 .price-old{
  font-size: 12px;
  color:#999999;
  text-decoration: line-through;
  margin-right: 0.15rem;
}
.inc-content .red{
  color:#FB4A3E;
}
.inc-content .tip-price1 .preferential{
  font-size: 14px;
}
.inc-content .tip-arrange{
  width:100%;
  overflow: hidden;
  background: #F8F8F8;
  border: 1px solid #DBDBDB;
  border-radius: 10px;
}
.inc-content .tip-arrange p{
  display: flex;
  height:0.4rem;
  line-height: 0.4rem;
  padding:0.2rem 0;
  font-size: 14px;
  margin:0 0.34rem;
  align-items: center;
}
.inc-content .tip-arrange .tip-time{
  border-bottom:1px #DBDBDB solid;
}
.inc-content .tip-arrange .tip-address{
  border-top:1px #DBDBDB solid;
}
.inc-content .tip-arrange p img{
  width:0.31rem;
  height: 0.31rem;
  margin-right: 0.2rem;
}
.inc-content .tip-matter{
  display: flex;
  height:0.4rem;
  line-height: 0.4rem;
  padding:0.35rem 0;
  font-size: 14px;
  margin:0.1rem 0;
  align-items: center;
}
.inc-content .tip-matter:not(:last-child){
  border-bottom:1px #DBDBDB solid;
}
.inc-content .tip-matter .matter-icon{
  width: 0.1rem;
  height: 0.1rem;
  background: #FB4A3E;
  border-radius: 50%;
  margin:0.14rem 0.2rem 0.15rem 0.1rem;
}
.inc-content .tip-matter .matter-title{
  font-size: 14px;
  color:#333333;
  font-weight: bold;
  margin-right:0.3rem;
}
.inc-content .tip-matter .matter-cont{
  flex-grow:2;
  color:#999;
}
.inc-content .tip-matter .matter-course{
  width:0.15rem;
  height:0.24rem;
  margin:0.04rem 0;
}
.inc-content .tip-matter .matter-course img{
  width:100%;
}
.inc-content .tip-matter .tip-address img{
  width: 0.34rem;
  height:0.35rem
}
.content2 .tip2{
  color:#333333;
  padding:5px 0;
  line-height: 0.5rem;;
}
</style>