<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-04-07 13:47:28
 * @LastEditTime : 2021-05-10 10:22:01
 * @LastEditors  : XiaoLei
-->
<template>
  <div class="course">
    <div v-for="(groupitem, groupindex) of detail.chapterWebVos" :key="groupindex">
      <div @click="toggleClass(groupindex)" v-if="detail.hasChapter" class="course-group">
        <span class="cg-title">{{groupitem.chapterName}}</span>
        <em class="arrow" :class="{'turnArrow' : listType[groupindex]}"></em>
      </div>
      <template v-if="detail.hasChapter">
        <div v-if="listType[groupindex]">
          <div class="course-item" :class="{'course-can': classitem.liveStatus === 2 || classitem.liveStatus === 3 || classitem.liveStatus === 5}" v-for="(classitem, classindex) of groupitem.courseWebVos" :key="classindex" @click="toPlay(classitem)">
            <div class="ci-left">
              <div class="ci-title">
                <div class="play-icon"></div>
                <div class="title">{{classitem.courseName}}</div>
              </div>
              <div class="time">主讲：{{classitem.teacherName}} <span>{{classitem.classTime}}</span></div>
            </div>
            <div class="ci-right">
              <span :class="{
                'blue': classitem.liveStatus === 5,
                'green': classitem.liveStatus === 3,
                'red': classitem.liveStatus === 2,
                }">{{classitem.liveStatus | liveStatusFilter}}</span>
            </div>
          </div>
        </div>
      </template>
      <template v-if="!detail.hasChapter">
        <div>
          <div class="course-item" :class="{'course-can': classitem.liveStatus === 2 || classitem.liveStatus === 3 || classitem.liveStatus === 5}" v-for="(classitem, classindex) of groupitem.courseWebVos" :key="classindex" @click="toPlay(classitem)">
            <div class="ci-left">
              <div class="ci-title">
                <div class="play-icon"></div>
                <div class="title">{{classitem.courseName}}</div>
              </div>
              <div class="time">主讲：{{classitem.teacherName}} <span>{{classitem.classTime}}</span></div>
            </div>
            <div class="ci-right">
              <span :class="{
                'blue': classitem.liveStatus === 5,
                'green': classitem.liveStatus === 3,
                'red': classitem.liveStatus === 2,
                }">{{classitem.liveStatus | liveStatusFilter}}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <van-empty v-if='!detail.chapterWebVos' description="暂无数据" />
  </div>
</template>

<script>
import Vue from 'vue'
import { LIVE_STATUS } from '@/config/config'
export default {
  name: 'CourseList',
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      listType: [
        true
      ],
    }
  },
  filters: {
    liveStatusFilter(val) {
      return LIVE_STATUS[val] || '未开始'
    }
  },
  mounted() {
  },
  methods:{
    toggleClass(index) {
      // this.listType[index] = !this.listType[index]
      Vue.set(this.listType, index, !this.listType[index])
    },
    toPlay(item) {
      // 如果为购买，提示他先购买
      if(+this.detail.buyFlag !== 1) {
        if(this.$store.state.userInfo && this.$store.state.userInfo.token) {
          this.$toast('请先购买该课程')
        }
        else {
          this.$store.dispatch('toggleDialog', true)
        }
        return false
      }
       //  1 暂无直播  2 直播中  3 点播  4   视频生成中   5  回放
      if(item.liveStatus === 2 || item.liveStatus === 3 || item.liveStatus === 5) {
        const id = item.id
        this.$router.push({path: `/course/class/${id}?id=${this.detail.id}`})
      }
    }
  }
}
</script>

<style lang="less" scoped>
.course{
  // margin-top:20px;
}
.course-group{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  cursor: pointer;
  margin: 0 3%;
  
}
.cg-title{
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  line-height: 18px;
  height: 0.5rem;
  line-height: 0.5rem;
  margin:0.2rem 0;
}
.cg-title  .arrow{
  width: 0.15rem;
  height: 0.1rem;
  display: block;
  background: url("../../assets/images/icon/icon-4.png") no-repeat;
  background-size: contain;
  transition: all 0.3s;
}
.course-item{
  display: flex;
  margin:0 3%;
  height: 1.3rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  transition: all 0.3s;
  
}

.ci-title{
  display: flex;
  align-items: center;
}
.course-item  .title{
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 0.6rem;
  margin-left: 0.1rem;
  overflow: hidden;
  text-overflow:ellipsis; 
  white-space: nowrap;
}
.course-item  .time{
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 0.4rem;
  margin-left: 0.5rem;
}
.course-item  .time span{
  padding-left: 0px;
}
.ci-left{
  // display: flex;
  // align-items: center;
}
.ci-right{
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 1.3rem;
  flex-grow:0;
  flex-shrink:0;
}
.play-icon{
  width: 0.35rem;
  height: 0.35rem;
  background: url('../../assets/images/icon/icon-3.png') no-repeat;
  background-size: contain;
}
.video-icon{
  width: 0.44rem;
  height: 0.3rem;
  background: url('../../assets/images/icon/icon-6.png') no-repeat;
  background-size: contain;
}
.arrow{
  width: 15px;
  height: 9px;
  display: block;
  background: url("../../assets/images/icon/icon-4.png") no-repeat;
  background-size: contain;
  transition: all 0.3s;
}
.turnArrow{
  transform: rotate(180deg);
}
.blue{
  color: #2E8DF4;
}
.green{
  color: #00B262;
}
.red{
  color: #FF3F47;
}
</style>