<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-03-26 17:55:09
 * @LastEditTime : 2022-03-07 09:45:47
 * @LastEditors  : BigBigger
-->
<template>
  <div class="detail-card">
    <div class="inc-content">
      <div class="class-select" @click="getClassList">
        <p>选择班级</p>
        <i></i>
        <img src="@/assets/images/icon/icon-4.png">
      </div>
      <div class="price-detail">
        <span class="price-now">￥{{detail.currentPrice}}</span>
        <span class="discount" v-if="discount > 0">（总价优惠{{discount}}元）</span>
      </div>
      <div class="class-detail">
        <span class="info">班级信息：</span>
        <div class="class-list">
          <span v-for="(item,index) in flightNameList" :key="index">{{item}}</span>
        </div>
      </div>
    </div>
    <div class="tip-matter" v-if="detail.protocolId" @click="toProtocol">
      <i class="matter-icon"></i>
      <span class="matter-title">课程协议</span>
      <span class="matter-cont">协议模板</span>
      <van-icon name="arrow" />
    </div>
  </div>
</template>

<script>
import { BTNSTATEFILTER } from '@/config/config'
import { getLessonGroupTips, getFlightName } from '@/api/api'

export default {
  name: "ClassDetailCard",
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    classList: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      discount: 0,
      flightIds: [],
      flightNameList: [],
    }
  },
  watch: {
    classList: function () {
      let orderInfo = this.$store.state.orderInfo || null
      if(orderInfo) {
        this.flightIds = orderInfo.classInfo
        this.getFlightName()
      }
    },
    
  },
  mounted() {
    this.discount = this.detail.originalPrice - this.detail.currentPrice
    let orderInfo = this.$store.state.orderInfo || null
    if(orderInfo) {
      this.flightIds = orderInfo.classInfo
      this.getFlightName()
    }
  },
  methods: {
    getClassList(){
      this.$emit('getClass',true)
    },
    async getFlightName() {
      let params = {
        flightIds: this.flightIds
      }
      let response = await getFlightName(params)
      if(+response.returnCode !== 10001) {
        return false
      }
      let str = response.data
      let strs = str.split(",");
      this.flightNameList = strs
      //console.log(this.flightNameList)
      
    },
    toProtocol() {
      this.$router.push({
        name: 'protocol',
        params: {
          id: this.detail?.protocolId
        }
      })
    },
  },
  computed: {
    
  }
}
</script>

<style lang="less" scoped>
.detail-card{
  background: #fff;
  // display: flex;
  // justify-content: space-between;
  padding:0.2rem 0.4rem;
}
.inc-content{
  color:#333333;
  text-align: left;
  width:100%;
}
.class-select{
  height:35px;
  line-height: 35px;
  background: #FDEFEF;
  border: 1px solid #FF7773;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0 10px 0 20px;
}
.class-select p{
  font-size: 14px;
  color:#FF3F47;
  margin-right:5px;
}
.class-select img{
  flex-shrink:0;
  flex-grow:0;
  width:18px;
  height:10px;
}

.price-detail{
  color:#FF3F47;
  height:40px;
  line-height: 40px;
  display: flex;
  padding:10px 0;
} 
.price-detail .price-now{
  font-size: 20px;
  color:#FF3F47;
  font-weight: bold;
  margin-right: 0.15rem;
}
.price-detail .discount{
  font-size: 16px;
}
.class-detail{
  font-size: 14px;
  display: flex;
  color:#333333;
  line-height: 24px;
}
.class-detail .info{
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: bold;
}
.class-detail .class-list span{
  display: block;
}
.tip-matter{
  display: flex;
  height:0.4rem;
  line-height: 0.4rem;
  padding:0.35rem 0;
  font-size: 14px;
  margin:0.1rem 0;
  align-items: center;
}
.tip-matter .matter-title{
  font-size: 14px;
  color:#333333;
  font-weight: bold;
  margin-right:0.3rem;
}
.tip-matter .matter-cont{
  flex-grow:2;
  color:#999;
}
.tip-matter .matter-course{
  width:0.15rem;
  height:0.24rem;
  margin:0.04rem 0;
}
.tip-matter .matter-course img{
  width:100%;
}
</style>